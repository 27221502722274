import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 450 123", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd"
}, ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 60, cy: 63, r: 57, style: {
  fill: "none",
  stroke: "white",
  strokeWidth: 6
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M63,27.75L63,55.167L81,55.167L57,98.25L57,70.833L39,70.833L63,27.75Z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "white",
  strokeWidth: 5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(5.62003,0,0,5.62003,-1024.39,-238.629)" }, /* @__PURE__ */ React.createElement("path", { d: "M207.364,48.93L207.364,57.33L209.39,57.33L209.39,48.93L207.364,48.93ZM208.636,50.599L210.278,50.599C210.687,50.599 211.009,50.69 211.242,50.873C211.475,51.056 211.592,51.323 211.592,51.674C211.592,52.022 211.475,52.288 211.242,52.473C211.009,52.657 210.687,52.749 210.278,52.749L208.636,52.749L208.636,54.418L210.278,54.418C210.996,54.418 211.596,54.307 212.079,54.086C212.561,53.864 212.926,53.547 213.173,53.135C213.42,52.723 213.544,52.236 213.544,51.674C213.544,51.104 213.42,50.615 213.173,50.207C212.926,49.799 212.561,49.484 212.079,49.262C211.596,49.041 210.996,48.93 210.278,48.93L208.636,48.93L208.636,50.599Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.606,53.13C215.606,52.632 215.705,52.194 215.904,51.815C216.103,51.436 216.381,51.138 216.736,50.922C217.092,50.706 217.507,50.598 217.981,50.598C218.472,50.598 218.894,50.706 219.246,50.922C219.598,51.138 219.871,51.436 220.064,51.815C220.258,52.194 220.355,52.632 220.355,53.13C220.355,53.623 220.256,54.06 220.057,54.442C219.858,54.824 219.581,55.122 219.227,55.338C218.873,55.554 218.458,55.662 217.981,55.662C217.507,55.662 217.092,55.554 216.736,55.338C216.381,55.122 216.103,54.824 215.904,54.442C215.705,54.06 215.606,53.623 215.606,53.13ZM213.46,53.13C213.46,53.778 213.571,54.375 213.793,54.92C214.015,55.465 214.327,55.938 214.731,56.339C215.135,56.739 215.613,57.049 216.166,57.269C216.718,57.489 217.323,57.599 217.981,57.599C218.641,57.599 219.247,57.489 219.798,57.269C220.349,57.049 220.826,56.739 221.23,56.339C221.634,55.938 221.947,55.465 222.169,54.92C222.391,54.375 222.502,53.778 222.502,53.13C222.502,52.479 222.389,51.886 222.165,51.349C221.941,50.813 221.624,50.35 221.215,49.959C220.806,49.569 220.328,49.267 219.779,49.054C219.231,48.842 218.632,48.736 217.981,48.736C217.343,48.736 216.749,48.842 216.199,49.054C215.649,49.267 215.17,49.569 214.761,49.959C214.352,50.35 214.033,50.813 213.804,51.349C213.574,51.886 213.46,52.479 213.46,53.13Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.864,53.723L228.562,48.51L226.278,53.723L224.627,48.93L222.22,48.93L225.855,57.825L228.562,52.758L231.268,57.825L234.904,48.93L232.496,48.93L230.864,53.723Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M236.287,57.33L240.523,57.33L240.523,55.662L236.287,55.662L236.287,57.33ZM236.287,50.599L240.523,50.599L240.523,48.93L236.287,48.93L236.287,50.599ZM236.287,53.735L240.283,53.735L240.283,52.104L236.287,52.104L236.287,53.735ZM234.94,48.93L234.94,57.33L236.892,57.33L236.892,48.93L234.94,48.93Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M243.636,53.526L245.994,57.33L248.373,57.33L245.746,53.526L243.636,53.526ZM241.54,48.93L241.54,57.33L243.566,57.33L243.566,48.93L241.54,48.93ZM242.812,50.651L244.454,50.651C244.729,50.651 244.964,50.693 245.158,50.779C245.352,50.865 245.502,50.989 245.608,51.152C245.714,51.315 245.768,51.514 245.768,51.749C245.768,51.978 245.714,52.176 245.608,52.341C245.502,52.507 245.352,52.632 245.158,52.718C244.964,52.804 244.729,52.847 244.454,52.847L242.812,52.847L242.812,54.418L244.603,54.418C245.321,54.418 245.921,54.302 246.404,54.071C246.886,53.839 247.251,53.516 247.498,53.101C247.745,52.687 247.869,52.211 247.869,51.674C247.869,51.129 247.745,50.651 247.498,50.24C247.251,49.83 246.886,49.509 246.404,49.277C245.921,49.046 245.321,48.93 244.603,48.93L242.812,48.93L242.812,50.651Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("rect", { x: 248.26, y: 48.93, width: 2.064, height: 8.4, style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.866,53.13C252.866,52.632 252.968,52.194 253.172,51.815C253.376,51.436 253.659,51.138 254.023,50.922C254.386,50.706 254.804,50.598 255.278,50.598C255.819,50.598 256.28,50.725 256.659,50.978C257.038,51.231 257.329,51.566 257.533,51.983C257.737,52.399 257.839,52.856 257.839,53.354C257.839,53.847 257.737,54.278 257.533,54.647C257.329,55.016 257.047,55.303 256.685,55.506C256.323,55.71 255.904,55.811 255.427,55.811C254.953,55.811 254.522,55.701 254.134,55.48C253.746,55.259 253.438,54.948 253.209,54.546C252.98,54.145 252.866,53.673 252.866,53.13ZM250.72,53.13C250.72,53.778 250.832,54.375 251.056,54.92C251.281,55.465 251.599,55.938 252.01,56.339C252.421,56.739 252.904,57.049 253.459,57.269C254.014,57.489 254.62,57.599 255.278,57.599C255.939,57.599 256.542,57.489 257.088,57.269C257.634,57.049 258.107,56.739 258.509,56.339C258.91,55.938 259.219,55.465 259.436,54.92C259.653,54.375 259.762,53.778 259.762,53.13C259.762,52.479 259.651,51.886 259.429,51.349C259.207,50.813 258.895,50.35 258.494,49.959C258.092,49.569 257.619,49.267 257.073,49.054C256.527,48.842 255.929,48.736 255.278,48.736C254.64,48.736 254.045,48.842 253.493,49.054C252.94,49.267 252.456,49.569 252.04,49.959C251.623,50.35 251.299,50.813 251.067,51.349C250.836,51.886 250.72,52.479 250.72,53.13ZM254.594,53.73L258.567,57.479L260.744,57.479L256.697,53.73L254.594,53.73Z", style: {
  fill: "white",
  fillRule: "nonzero"
} })));
export default SvgLogo;
