import { Outlet } from "@tanstack/react-router";
import { Header } from "./elements/header";
import { Navbar } from "@/features/layouts/navigation/navbar";

export const MainLayout = () => {
  return (
    <div className="h-full w-full flex flex-row overflow-y-auto gap-3">
      <div className="hidden md:flex pl-3">
        <Navbar />
      </div>
      <div className="h-full w-full overflow-y-auto pr-3">
        <Header />
        <div className="flex-1 h-max overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
