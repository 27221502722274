import { CircleHelp } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

export function HelpBadge() {
  const { t } = useTranslation("layout-menu");

  return (
    <div className="flex flex-col gap-1 h-44 w-56 bg-turquoise dark:border-2 dark:bg-background mt-20 rounded-2xl p-4 bg-[url('/background-button.png')] bg-cover bg-no-repeat bg-center">
      <div className="flex items-center mb-4">
        <div className="flex text-turquoise dark:text-card justify-center items-center h-9 w-9 bg-card dark:bg-turquoise rounded-2xl">
          <CircleHelp height={"18px"} width={"18px"} strokeWidth={2} />
        </div>
        <p className="text-white dark:text-foreground font-bold text-sm ml-2">
          {t("need_help")}
        </p>
      </div>
      <div className="flex justify-center mt-2">
        <a href="https://poweriq.pl/#contact" target="_blank" rel="noopener noreferrer">
          <Button variant="outline" size={"lg"} className="w-full max-w-xs">
            {t("contact_us")}
          </Button>
        </a>
      </div>
    </div>
  );
}
