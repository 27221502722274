import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
  ComposedChart,
  Legend,
} from "recharts";
import { useViewportSize } from "../useViewportSize";

type SpotGraphProps = {
  data: Array<{
    name: string;
    quantified: number;
    volue: number;
    fixing_i_price: number;
    fixing_ii_price: number;
    pse: number;
  }>;
  unit: string;
  avg: {
    quantified: number;
    volue: number;
    fixing_i_price: number;
    fixing_ii_price: number;
    pse: number;
  };
  last_fixing_i_price: string;
  last_pse: string;
};

const spotColors = {
  quantified: "#4FD1C5",
  volue: "#6D34B1",
  fixing_i_price: "orange",
  fixing_ii_price: "green",
  pse: "pink",
  stop: "#696969",
};

const defaultHiddenLines = {
  quantified: false,
  volue: true,
  fixing_i_price: false,
  fixing_ii_price: true,
  pse: true,
  quantified_avg: false,
  volue_avg: true,
  fixing_i_price_avg: false,
  fixing_ii_price_avg: true,
  pse_avg: true,
};

const CustomTooltip = ({ active, payload, label, unit, avg }) => {
  const { t } = useTranslation("graphs");
  if (active && payload && payload.length) {
    return (
      <div className="text-xs bg-graphTooltip text-white p-4 rounded-md">
        <div className="text-lightGray dark:text-muted-foreground">
          {new Date(label).toLocaleString()}
        </div>
        <div>
          {payload.map((data) => (
            <div key={data.name} className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: data.stroke,
                }}
              />
              {data.name}: {data.value} {unit}
            </div>
          ))}
          <div className="m-4" />
          {avg.quantified != null && (
            <div className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: spotColors.quantified,
                }}
              />
              {t("spot.quantified_avg")}: {avg.quantified?.toFixed(2)} {unit}
            </div>
          )}
          {avg.volue != null && (
            <div className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: spotColors.volue,
                }}
              />
              {t("spot.volue_avg")}: {avg.volue?.toFixed(2)} {unit}
            </div>
          )}
          {avg.fixing_i_price != null && (
            <div className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: spotColors.fixing_i_price,
                }}
              />
              {t("spot.fixing_i_price_avg")}: {avg.fixing_i_price?.toFixed(2)}{" "}
              {unit}
            </div>
          )}
          {avg.fixing_ii_price != null && (
            <div className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: spotColors.fixing_ii_price,
                }}
              />
              {t("spot.fixing_ii_price_avg")}: {avg.fixing_ii_price?.toFixed(2)}{" "}
              {unit}
            </div>
          )}
          {avg.pse != null && (
            <div className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: spotColors.pse,
                }}
              />
              {t("spot.pse")}: {avg.pse?.toFixed(2)} {unit}
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export const SpotGraph: FC<SpotGraphProps> = ({
  data,
  unit,
  avg,
  last_fixing_i_price,
  last_pse,
}) => {
  const x = Math.floor(data.length / 5);
  const { t } = useTranslation("graphs");
  const { isMobile } = useViewportSize();
  const [hiddenLines, setHiddenLines] = useState(defaultHiddenLines);

  const selectBar = (e) => {
    setHiddenLines({
      ...hiddenLines,
      [e.id]: !hiddenLines[e.id],
    });
  };

  const tgeStopLineHidden =
    hiddenLines.fixing_i_price === true && hiddenLines.fixing_ii_price === true;

  const pseStopLineHidden = hiddenLines.pse === true;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        margin={{ top: 30, right: 30, left: 45, bottom: 30 }}
        data={data}
      >
        <defs>
          <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" stopColor={spotColors.volue} stopOpacity={0.5} />
            <stop
              offset="1"
              stopColor={`hsl(0, 0%, 100%)`}
              stopOpacity={0.33}
            />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0"
              stopColor={spotColors.quantified}
              stopOpacity={0.5}
            />
            <stop
              offset="1"
              stopColor={`hsl(0, 0%, 100%)`}
              stopOpacity={0.33}
            />
          </linearGradient>

          <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0"
              stopColor={spotColors.fixing_i_price}
              stopOpacity={0.5}
            />
            <stop
              offset="1"
              stopColor={`hsl(0, 0%, 100%)`}
              stopOpacity={0.33}
            />
          </linearGradient>

          <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0"
              stopColor={spotColors.fixing_ii_price}
              stopOpacity={0.5}
            />
            <stop
              offset="1"
              stopColor={`hsl(0, 0%, 100%)`}
              stopOpacity={0.33}
            />
          </linearGradient>

          <linearGradient id="colorUv5" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" stopColor={spotColors.pse} stopOpacity={0.5} />
            <stop
              offset="1"
              stopColor={`hsl(0, 0%, 100%)`}
              stopOpacity={0.33}
            />
          </linearGradient>
        </defs>
        <ReferenceLine
          x={last_fixing_i_price}
          stroke={spotColors.stop}
          strokeWidth={3}
          className={`${tgeStopLineHidden && "hidden"}`}
        />
        <ReferenceLine
          x={last_pse}
          stroke={spotColors.stop}
          strokeWidth={3}
          className={`${pseStopLineHidden && "hidden"}`}
        />
        <XAxis
          dataKey="name"
          tickFormatter={(x) => new Date(x.toString()).toLocaleDateString()}
          interval={x}
          tickMargin={20}
          angle={isMobile ? 45 : 0}
          className="text-xs"
        />
        <YAxis
          tickCount={7}
          tickMargin={20}
          orientation="right"
          tickFormatter={(value) => {
            return value > 2000 ? `${value / 1000}k` : value;
          }}
          className="text-xs"
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          offset={40}
          cursor={{ stroke: spotColors.quantified, strokeWidth: 3 }}
          labelFormatter={(label) => new Date(label).toLocaleString()}
          // @ts-expect-error custom solution for tooltip
          content={<CustomTooltip unit={unit} avg={avg} />}
        />
        <Area
          type="monotone"
          dataKey={"fixing_i_price"}
          name={t("spot.fixing_i_price")}
          stroke={spotColors.fixing_i_price}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          fillOpacity={0}
          hide={hiddenLines["fixing_i_price"] === true}
          fill={"url(#colorUv2)"}
        />
        <Area
          type="monotone"
          dataKey={"fixing_ii_price"}
          name={t("spot.fixing_ii_price")}
          stroke={spotColors.fixing_ii_price}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          fillOpacity={0}
          hide={hiddenLines["fixing_ii_price"] === true}
          fill={"url(#colorUv3)"}
        />
        <Area
          type="monotone"
          dataKey={"quantified"}
          name={t("spot.quantified")}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          stroke={spotColors.quantified}
          fillOpacity={1}
          hide={hiddenLines["quantified"] === true}
          fill={"url(#colorUv)"}
        />
        <Area
          type="monotone"
          dataKey={"volue"}
          name={t("spot.volue")}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          stroke={spotColors.volue}
          fillOpacity={1}
          hide={hiddenLines["volue"] === true}
          fill={"url(#colorUv4)"}
        />
        <Area
          type="monotone"
          dataKey={"pse"}
          name={t("spot.pse")}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          stroke={spotColors.pse}
          fillOpacity={1}
          hide={hiddenLines["pse"] === true}
          fill={"url(#colorUv5)"}
        />
        <ReferenceLine
          y={avg.quantified}
          stroke={spotColors.quantified}
          strokeWidth={3}
          className={`${hiddenLines["quantified_avg"] === true && "hidden"} `}
        />
        <ReferenceLine
          y={avg.volue}
          stroke={spotColors.volue}
          strokeWidth={3}
          className={`${hiddenLines["volue_avg"] === true && "hidden"} `}
        />
        <ReferenceLine
          y={avg.fixing_i_price}
          stroke={spotColors.fixing_i_price}
          strokeWidth={3}
          className={`${hiddenLines["fixing_i_price_avg"] === true && "hidden"} `}
        />
        <ReferenceLine
          y={avg.fixing_ii_price}
          stroke={spotColors.fixing_ii_price}
          strokeWidth={3}
          className={`${hiddenLines["fixing_ii_price_avg"] === true && "hidden"} `}
        />
        <ReferenceLine
          y={avg.pse}
          stroke={spotColors.pse}
          strokeWidth={3}
          className={`${hiddenLines["pse_avg"] === true && "hidden"} `}
        />
        <Legend
          iconSize={10}
          verticalAlign="top"
          onClick={selectBar}
          wrapperStyle={{
            top: 0,
            fontSize: "13px",
          }}
          payload={[
            {
              value: t("spot.fixing_i_price"),
              type: "line",
              id: "fixing_i_price",
              color: hiddenLines.fixing_i_price
                ? "grey"
                : spotColors.fixing_i_price,
            },
            {
              value: t("spot.fixing_i_price_avg"),
              type: "circle",
              id: "fixing_i_price_avg",
              color: hiddenLines.fixing_i_price_avg
                ? "grey"
                : spotColors.fixing_i_price,
            },
            {
              value: t("spot.fixing_ii_price"),
              type: "line",
              id: "fixing_ii_price",
              color: hiddenLines.fixing_ii_price
                ? "grey"
                : spotColors.fixing_ii_price,
            },
            {
              value: t("spot.fixing_ii_price_avg"),
              type: "circle",
              id: "fixing_ii_price_avg",
              color: hiddenLines.fixing_ii_price_avg
                ? "grey"
                : spotColors.fixing_ii_price,
            },
            {
              value: t("spot.quantified"),
              type: "line",
              id: "quantified",
              color: hiddenLines.quantified ? "grey" : spotColors.quantified,
            },
            {
              value: t("spot.quantified_avg"),
              type: "circle",
              id: "quantified_avg",
              color: hiddenLines.quantified_avg
                ? "grey"
                : spotColors.quantified,
            },
            {
              value: t("spot.volue"),
              type: "line",
              id: "volue",
              color: hiddenLines.volue ? "grey" : spotColors.volue,
            },
            {
              value: t("spot.volue_avg"),
              type: "circle",
              id: "volue_avg",
              color: hiddenLines.volue_avg ? "grey" : spotColors.volue,
            },
            {
              value: t("spot.pse"),
              type: "line",
              id: "pse",
              color: hiddenLines.pse ? "grey" : spotColors.pse,
            },
            {
              value: t("spot.pse_avg"),
              type: "circle",
              id: "pse_avg",
              color: hiddenLines.pse_avg ? "grey" : spotColors.pse,
            },
          ]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
